
.r-view--vtable-container {
    width: 100%;
    margin: 20px auto;
    text-align: center;
}

.r-view--vtable-title {
    font-size: 1.5em;
    margin-bottom: 15px;
}

.r-view--vtable {
    width: 100%;
    border-collapse: collapse;
    /* background-color: #2e2e2e; */
}

.r-view--vtable-head {
    background-color: #333333;
}

.r-view--vtable-header {
    padding: 12px 15px;
    border: 1px solid #444;
    font-weight: bold;
    color: #f0f0f0;
    text-align: center;
}

.r-view--vtable-row:hover {
    background-color: #333333;
}

.r-view--vtable-cell {
    padding: 12px 15px;
    border: 1px solid #444;
    text-align: center;
    color: #ddd;
}

.r-view--vtable-cell-name {
    text-align: left;
}

.r-view--vtable-cell-date {
    width: 20%;
}

.r-view--vtable-title2 {
    text-align: left;
    font-size: 36px;
    color: white;
    font-weight: 700;
    margin-bottom: 30px;
}

.r-view--tlist-btn {
    background-color: #333;
    color: #f0f0f0;
    font-family: Montserrat;
    border: none;
    padding: 10px 15px;
    margin-right: 5px;
    /* border-radius: 5px; */
    cursor: pointer;
}

.r-view--tlist-btn:hover {
    background-color: #d71f26;
}

.r-view--tlist-btn-active {
    background-color: #d71f26;
    color: #f0f0f0;
    font-family: Montserrat;
    border: none;
    padding: 10px 15px;
    margin-right: 5px;
    /* border-radius: 5px; */
    cursor: pointer;
}

.r-view--roster-container {
    width: 100%;
    color: #ffffff;
    padding: 20px;
}

.r-view--roster-container h2 {
    font-size: 38px;
    text-align: center;
    margin-bottom: 10px;
}


.r-view--create-game-form label {
    margin-bottom: 5px;
    display: block;
}

.r-view--create-game-form input {
    background-color: #333;
    padding: 7px;
    border: none;
    width: 100%;
    color: white;
}

.r-view--create-game-form select {
    background-color: #333;
    padding: 7px;
    border: none;
    width: 100%;
    color: white;
}

.r-view--create-game-form textarea {
    background-color: #333;
    padding: 7px;
    border: none;
    width: 100%;
    color: white;
}

.r-view--text-center {
    text-align: center;
}

.r-view--text-right {
    text-align: right;
}

.r-view--text-left {
    text-align: left;
}

.r-view--vtable-cell-name {
    text-align: left;
}

.r-view--vtable-cell-points {
    width: 15%;
}

.r-view--vtable-cell-15 {
    width: 15%;
}

.r-view--warlord-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    text-decoration: underline;
    cursor: pointer;
    color: #f0f0f0;
}

.r-view--clone-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    text-decoration: underline;
    cursor: pointer;
    color: #f0f0f0;
}
.r-view--remove-cloned-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    text-decoration: underline;
    cursor: pointer;
    color: #f0f0f0;
}

.r-view--equipments {
    color: #a1a0a0;
}

.r-view--warlord-mark {
    color: #d71f26;
}

.r-view-unit-type {
    font-size: 1.5rem;
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-thickness: 1px;
}

.r-view-unit-weapon-type {
    font-size: 0.8rem;
}

.r-view-unit-name {
    font-size: 1rem;
    font-weight: bold;
}

.r-view-unit-weapon-name {
    font-size: 0.8rem;
    padding-left: 10px;
}

.r-view-unit-model-name {
    font-size: 0.9rem;
    padding-left: 10px;
}

.r-view-summary-number {
    display: inline-block ;
}

.r-view-summary {
    text-align: center;
}

.r-view-adjustments {
    padding-bottom: 20px;
    display: inline-block;
}

.r-view-adjustment {
    padding-right: 10px;
    display: inline;
}

.r-view-adjustment-row {
    display: flex;
    padding-bottom: 10px;
    padding-top: 10px;
}

.r-view-adjustment-description {
    width: 250px;
    font-size: 14px;
    color: #a1a0a0;
}

.r-view-adjustment-value {
    font-size: 14px;
    color: #a1a0a0;
}