@font-face {
  font-family:Montserrat ;
  src: url(./_components/font/Montserrat/Montserrat-VariableFont_wght.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: Montserrat;
}

body {
  background-color: #202125;

}

/* https://loading.io/ */
.spinner {
  color:#b03030;
}

.spinner,
.spinner:after {
  box-sizing: border-box;
}
.spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.spinner:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid currentColor;
  border-color: currentColor transparent currentColor transparent;
  animation: spinner 1.2s infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

