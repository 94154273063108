
.u-l-loginForm {
  background-color: #eee;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  padding: 2rem 10rem 2rem 10rem;
  margin: 4rem auto;
  width: 30rem;
}

.u-l-loginInput {
  width: 24rem;
}

.u-l-signInHeader {
  margin: 0;
}

.u-l-signInSubheader {
  margin: 0.5rem 0 0rem;
}

.u-l-passwordItem {
  margin-bottom: 0.5rem;
}

.u-l-forgotPassword {
  text-align: end;
}

.u-l-noAccountLabel {
  height: 1.2rem;
  margin: 4rem 0 0.8rem;
}

.u-l-noAccountAction {
  margin-left: 0.5rem;
}

.u-l-error {
  color: red;
  padding-bottom: 0.5rem;
}