
.m-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0px;
    margin: 0 auto;
    max-width: 1200px;
    color: white;
}

.m-menu {
    display: flex;
    list-style-type: none;
    gap: 20px;
}

.m-menu li a {
    text-decoration: none;
    color: #ffffff;
    font-weight:500;
    padding: 10px 15px;
}

.m-menu li a:hover {
    color:#b03030;
}

.m-menu-active {
    color:#b03030 !important;
}

.m-user-info {
    display: flex;
    align-items: center;
    color: #ffffff;
    gap: 10px;
}

.m-logo {
    width: 120px;
    height: 120px;
}

.m-username {
    font-weight:500;
}


.m-button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-weight:500;
    font-size: 0.9em;
}

.m-button-signin {
    background-color:#fff ;
    color: #000000;
}

.m-button-join-the-fight {
    background-color:#AC2B2B;
    color: #fff;
}



.m-div-voting-tags {

}

.m-span-voting-tag {
    display: inline-block;
    position: relative;
    padding-right: 10px;
}

.m-tag {
    color: #fff;
}

.m-tag_badge {
    background-color: #fa3e3e;
    border-radius: 2px;
    color: white;

    padding: 1px 3px;
    font-size: 7px;

    position: absolute;
    top: 0;
    right: 0;
}
