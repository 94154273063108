.u-r-registrationTitle {
  padding-top: 2rem;
  font-size: larger;
  font-weight: bold;
}

.u-r-registerForm {
  background-color: #eee;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  padding: 2rem 10rem 2rem 10rem;
  margin: 4rem auto;
  width: 30rem;
}

.u-r-signUpHeader {
  margin: 0;
}

.u-r-signUpSubheader {
  margin: 0.5rem 0 2rem;
}

.u-r-registerInput {
  width: 24rem;
}

.u-r-registerButton {
  align-self: flex-end;
}

.u-r-hasAccountLabel {
  height: 1.2rem;
  margin: 4rem 0 0.8rem;
}

.u-r-hasAccountAction {
  margin-left: 0.5rem;
}

.u-r-activation-note {
  font-size: 12px;
  color: #a1a0a0;
  display: inline;
}

.u-r-error {
  color: red;
}