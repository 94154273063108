
.hamburger-menu {
    position: relative;
}

.hamburger-menu i {
    color: #a1a0a0;
}

.menu-content {
    position: absolute;
    top: 35px;
    left: 0;
    background-color: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.menu-content a {
    padding: 12px 16px;
    text-decoration: none;
    color: #333;
    display: block;
}

.menu-content a:hover {
    background-color: #ddd;
}
