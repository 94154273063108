
.c-f-print-vtable-container {
  width: 100%;
  margin: 20px auto;
  text-align: center;
}

.c-f-print-vtable-title {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.c-f-print-vtable {
  width: 100%;
  border-collapse: collapse;
  /* background-color: #2e2e2e; */
}

.c-f-print-vtable-head {
  background-color: #333333;
}

.c-f-print-vtable-header {
  padding: 12px 15px;
  border: 1px solid #444;
  font-weight: bold;
  color: #f0f0f0;
  text-align: center;
}

.c-f-print-vtable-row:hover {
  background-color: #333333;
}

.c-f-print-vtable-cell {
  padding: 12px 15px;
  border: 1px solid #444;
  text-align: center;
  color: #ddd;
}

.c-f-print-vtable-cell-name {
  text-align: left;
}

.c-f-print-vtable-cell-date {
  width: 20%;
}

.c-f-print-vtable-title2{
  text-align: left;
  font-size: 36px;
  color: white;
  font-weight: 700;
  margin-bottom: 30px;
}

.c-f-print-tlist-btn {
  background-color: #333;
  color: #f0f0f0;
  font-family: Montserrat ;
  border: none;
  padding: 10px 15px;
  margin-right: 5px;
  /* border-radius: 5px; */
  cursor: pointer;
}

.c-f-print-tlist-btn:hover {
  background-color: #d71f26;
}

.c-f-print-tlist-btn-active {
  background-color: #d71f26;
  color: #f0f0f0;
  font-family: Montserrat ;
  border: none;
  padding: 10px 15px;
  margin-right: 5px;
  /* border-radius: 5px; */
  cursor: pointer;
}

.c-f-print-custom-hr {
  border: none;
  border-top: 2px solid #ffffff; /* Adjust thickness as needed */
  margin: 40px 0; /* Optional spacing */
}

div .print-footer {
  color: #929191;
  text-align: center;
}

div .page {
  break-after: page;
}

.c-f-print-text-center {
  text-align: center;
}

@media print {
  * {
    font-size: 20pt;
    width: max-content !important;
    color: black;
    margin-left: 0px !important;
  }

  div .page {
    break-after: page;
  }

  div .print-footer {
    color: lightgrey;
  }

  .print-short {
    inline-size: 700px !important;;
  }


}


