
.l-button-join-now {
    margin-left: 10px;
}

.l-subheading-landing {
    font-size: 18px;
    color: #aaa;
}

.l-display-4 {
    font-size: 2rem;
    color: white;
}
.l-text-center {
    text-align: center;
}

.l-landing-details {
    color: #aaa;
}

.l-primary-btn {
    background-color: #d71f26;
    color: #f0f0f0;
    font-family: Montserrat;
    border: none;
    padding: 10px 15px;
    margin-right: 5px;
    cursor: pointer;
    text-align: center;
}

.l-card-border {
    border-radius: 0px;
}

.l-landing-details-head {
    font-size: 20px;
    color: #d32f2f;
    font-weight: 600;
    margin-top: 15px;
}

.l-card-no-border {
    border: none;
    background-color: transparent;
    box-shadow: none;
}

.l-stats-container {
    border: 1px solid #313234;
    padding: 20px;
    color: white;

}

.l-stats-container .l-stats-number {
    font-size: 30px;
    font-weight: bold;
}

.l-accordion {
    background-color: #202125;
}

.l-accordion-body {
    background-color: #202125;
    outline: none;
    color: white;
}

.l-accordion-button {
    background-color: #202125;
    outline: none;
    color: white;
}

.l-contact-container {
    width: 100%;
    background-color: #313234;
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.425);
    padding: 20px;
}

.l-contact-container h2 {
    font-size: 38px;
    text-align: center;
    margin-bottom: 10px;
}

.l-contact-container .quick-details {
    font-size: 18px;
    text-align: center;
}


.l-contact-form label {
    margin-bottom: 5px;
    display: block;
}

.l-contact-form input {
    background-color: #202125;
    padding: 7px;
    border: none;
    width: 100%;
    color: white;
}

.l-contact-form textarea {
    background-color: #202125;
    padding: 7px;
    border: none;
    width: 100%;
    color: white;
}

.l-display-6 {
    color: white;
}

.l-card {
    background-color: #313234;
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.425);
    width: 300px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    text-align: left;
    position: relative;
}

.l-icon-plus{
    display: flex;
    justify-content: space-between;
}





.grid {
    width: 500px;
    display: grid;
    gap: 0;
    background-image: url("https://t4.ftcdn.net/jpg/09/38/74/95/240_F_938749544_O0hm80rfSYbJIf2DrpnijZqTAC8Kcot6.jpg") !important;
}

.grid-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #15304f;
    background-color: transparent  !important;
    color: white;
    width: 50px;
    height: 50px;
    font-size: 14px;
}

.grid-cell-hide {
    visibility: hidden;
}

.grid-cell-flag {
    background-size: 50px;
    background-image: url("flag.png") !important;
    opacity: 50%;
}

.grid-cell-mark {
    background-color: #61dafb !important;
    opacity: 50% !important;
}

.dg-flag  {

    /*

    visibility: hidden;
    */




/*
 background-size: 50px;
    background-image: url("dg-flag.png") !important;
opacity: 50%;
*/


    /*
    background-color: #61dafb !important;
    opacity: 50% !important;
    */


}
