
.t-vv-state-past {
  list-style-type: disc;
}

.t-vv-state-empty {
  padding-top: 5px;
  list-style-type: none;
}

.t-vv-state-future {
  list-style-type: circle;
}

.t-vv-state-cell {
  color: #a1a0a0;
  font-size: 12px;
}

.t-vv-state-cell-active {
  color: #ffffff;
  font-size: 12px;
}

.t-vv-state-delete-button {
  background-color: #333;
  color: #f0f0f0;
  padding: 5px 5px;
  cursor: pointer;

  border: 1px solid #b0afaf;
  font-size: 12px;
  outline: none;
  border-radius: 4px;
}

.t-vv-state-create-round-button {
  background-color: #333;
  color: #f0f0f0;
  padding: 5px 5px;
  cursor: pointer;

  border: 1px solid #b0afaf;
  font-size: 12px;
  outline: none;
  border-radius: 4px;
}

.t-vv-state-end-button {
  background-color: #333;
  color: #f0f0f0;
  padding: 5px 5px;
  cursor: pointer;

  border: 1px solid #b0afaf;
  font-size: 12px;
  outline: none;
  border-radius: 4px;
}

.t-vv-state-row {
  padding-top: 5px;
}

.t-vv-players {
  display: table-row;
  width: 100%;
}

.t-vv-games {
  display: table-row;
  width: 100%;
}

.t-vv-players-name {
  width: 65%;
  display: table-cell;
  padding-right: 10px;
  font-size: 16px;
  color: #a1a0a0;
  text-align: left;
}

.t-vv-players-delete {
  padding-right: 5px;
}

.t-vv-players-position {
  width: 10%;
  display: table-cell;
  padding-right: 10px;
  font-size: 16px;
  color: #a1a0a0;
  text-align: center;
}

.t-vv-games-round {
  display: table-cell;
  padding-right: 10px;
  font-size: 14px;
  color: #a1a0a0;
  text-align: left;
}


.t-vv-games-table {
  display: table-cell;
  padding-right: 10px;
  font-size: 14px;
  color: #a1a0a0;
  text-align: left;
}

.t-vv-games-mission {
  display: table-cell;
  padding-right: 10px;
  font-size: 14px;
  color: #a1a0a0;
  text-align: left;
}


.t-vv-games-rule {
  display: table-cell;
  padding-right: 10px;
  font-size: 14px;
  color: #a1a0a0;
  text-align: left;
}

.t-vv-games-deploy {
  display: table-cell;
  padding-right: 10px;
  font-size: 14px;
  color: #a1a0a0;
  text-align: left;
}

.t-vv-games-layout {
  display: table-cell;
  padding-right: 10px;
  font-size: 14px;
  color: #a1a0a0;
  text-align: left;
}

.t-vv-games-player-name-row {
  display: table-cell;
}

.t-vv-games-player-name {
  padding-right: 10px;
  font-size: 14px;
  color: #a1a0a0;
  text-align: left;
}

.t-vv-games-player-vs {
  padding-right: 10px;
  font-size: 10px;
  color: #a1a0a0;
  text-align: left;
}

.t-vv-games-player-name-winner {
  padding-right: 10px;
  font-size: 14px;
  color: #20ab42;
  text-align: left;
}

.t-vv-games-link {
  color: #a1a0a0;
}

.t-vv-players-score {
  display: table-cell;
  padding-right: 10px;
  font-size: 16px;
  color: #a1a0a0;
  text-align: center;
}

.t-vv-players-header {
  display: table-cell;
  padding-right: 10px;
  font-size: 12px;
  color: #a1a0a0;
  text-align: left;
  font-weight: bold;
}

.t-vv-games-header {
  display: table-cell;
  padding-right: 10px;
  font-size: 12px;
  color: #a1a0a0;
  text-align: left;
  font-weight: bold;
}

.t-vv-games-text {
  display: table-cell;
  padding-right: 10px;
  font-size: 14px;
  color: #a1a0a0;
  text-align: left;
}

.t-vv-games-player-text {
  display: table-cell;
  padding-right: 10px;
  font-size: 14px;
  color: #a1a0a0;
  text-align: left;
}

.t-vv-games-player-winner-text {
  display: inline;
  font-size: 14px;
  color: #20ab42;
  text-align: left;
}

.t-vv-games-round-text {
  display: table-cell;
  padding-right: 10px;
  font-size: 14px;
  color: #a1a0a0;
  text-align: center;
}

.t-vv-games-table-text {
  display: table-cell;
  padding-right: 10px;
  font-size: 14px;
  color: #a1a0a0;
  text-align: center;
}

.t-vv-row {
  display: table-row;
  width: 100%;
}

.t-vv-info-row {
  padding-top: 5px;
  width: 100%;
}

.t-vv-cell {
  display: table-cell;
  padding-right: 5px;
  font-size: 12px;
}

.t-vv-text {
  font-size: 12px;
  color: #a1a0a0;
}
.t-vv-game-layout-preview {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #202125;
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 200px;
  border: 1px solid #ccc;
}

.t-vv-game-layout-preview  .title {
  white-space: pre-wrap;
  font-size: 14px;
  padding-bottom: 5px;
}

.t-vv-game-layout-preview  .layout {
  width: 100%;
  height: 100%;
}

.t-vv-game-deploy-preview {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #202125;
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 200px;
  border: 1px solid #ccc;
}

.t-vv-game-deploy-preview  .title {
  white-space: pre-wrap;
  font-size: 14px;
  padding-bottom: 5px;
}

.t-vv-game-deploy-preview  .deploy {
  width: 100%;
  height: 100%;
}

.t-vv-game-mission-preview {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #202125;
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 300px;
  border: 1px solid #ccc;
}

.t-vv-game-mission-preview  .title {
  white-space: pre-wrap;
  font-size: 14px;
  padding-bottom: 5px;
}

.t-vv-game-mission-preview .brief  {
  white-space: pre-wrap;
  color: #a1a0a0;
  font-style: italic;
  font-size: 10px;
}

.t-vv-game-mission-preview .text {
  white-space: pre-wrap;
  color: #a1a0a0;
}

.t-vv-game-rule-preview {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #202125;
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 300px;
  border: 1px solid #ccc;
}
.t-vv-game-rule-preview  .title {
  white-space: pre-wrap;
  font-size: 14px;
  padding-bottom: 5px;
}

.t-vv-game-rule-preview .brief  {
  white-space: pre-wrap;
  color: #a1a0a0;
  font-style: italic;
  font-size: 10px;
}

.t-vv-game-rule-preview .text {
  white-space: pre-wrap;
  color: #a1a0a0;
}


.t-vv-info-input-number {
  width: 50px;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 10px;
  outline: none;
  background-color: #333;
  color: #f0f0f0;
}

.t-vv-info-input {
  width: 150px;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 10px;
  outline: none;
  background-color: #333;
  color: #f0f0f0;
}

.t-vv-info-area {
  width: 300px;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 10px;
  outline: none;
  background-color: #333;
  color: #f0f0f0;
}

.t-vv-settings {
  padding-bottom: 10px;
}


.t-vv-registration-button {
  background-color: #333;
  color: #f0f0f0;
  padding: 5px 5px;
  cursor: pointer;

  border: 1px solid #b0afaf;
  font-size: 12px;
  outline: none;
  border-radius: 4px;

  margin-left: 10px;
}

.t-vv-join-info-ch {
  padding-bottom: 20px;
  color: #929191;
}

.t-vv-add-user-button {
  background-color: #333;
  color: #f0f0f0;
  padding: 5px 5px;
  cursor: pointer;

  border: 1px solid #b0afaf;
  font-size: 12px;
  outline: none;
  border-radius: 4px;

  margin-left: 10px;
}

.t-vv-stage-start-button {
  background-color: #333;
  color: #f0f0f0;
  padding: 5px 5px;
  cursor: pointer;

  border: 1px solid #b0afaf;
  font-size: 12px;
  outline: none;
  border-radius: 4px;
}

.t-vv-stage-calendar-close-button {
  background-color: #333;
  color: #f0f0f0;
  padding: 5px 5px;
  cursor: pointer;

  border: 1px solid #b0afaf;
  font-size: 12px;
  outline: none;
  border-radius: 4px;
}

.t-vv-select {
  padding-left: 5px;
  text-align: center;
  background-color: #333;
  width: 200px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
  border-radius: 4px;
  color: #f0f0f0;
}

.t-vv-select-tables {
  width: 100px;
}

.t-vv-info-calendar{
  color: #a1a0a0;
  padding-left: 10px;
}

.t-vv-info-cell {
  display: table-cell;
  font-size: 12px;
  align-content: center;
  color: #a1a0a0;
}
.t-vv-player{
  padding-right: 10px;
}

.t-vv-newplayer {
  width: 100%;
  padding-bottom: 25px;
}

.t-vv-newplayer-user {

}

.t-vv-newplayer-faction {
  display: inline;
  padding-left: 10px;
}

.t-vv-registration-faction {
}