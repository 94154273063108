
.g-v-game-details h2 {
  font-size: 38px;
  margin-bottom: 10px;
  text-align: center;
  color: white;
}

.g-v-buttons-container {
  padding-top: 50px;
}

.g-v-text-larger {
  font-size: 20px;
}


.g-v-gd-bold {
  font-weight: bold;
  color: white;
}

.g-v-gd-info {
  color: white;
}

.g-v-custom-hr {
  border: none;
  border-top: 2px solid #525252; /* Adjust thickness as needed */
  margin: 40px 0; /* Optional spacing */
}

.g-v-tlist-btn {
  background-color: #333;
  color: #f0f0f0;
  font-family: Montserrat ;
  border: none;
  padding: 10px 15px;
  margin-right: 5px;
  /* border-radius: 5px; */
  cursor: pointer;
}

.g-v-tlist-btn:hover {
  background-color: #d71f26;
}

.g-v-tlist-btn-active {
  background-color: #d71f26;
  color: #f0f0f0;
  font-family: Montserrat ;
  border: none;
  padding: 10px 15px;
  margin-right: 5px;
  /* border-radius: 5px; */
  cursor: pointer;
}


.g-v-text {
  font-size: 14px;
  color: #a1a0a0;
}

.g-v-c-g-r-main {
  padding-top: 10px;
}

.g-v-c-g-r-table {
  width: 100%;
}

.g-v-player {
  padding-top: 20px;
}

.g-v-c-g-r-row {
  display: table-row;
  width: 100%;
}
.g-v-c-g-r-cell {
  display: table-cell;
  padding-right: 5px;
  font-size: 12px;
}

.g-v-info-text {
  font-size: 14px;
  color: #a1a0a0;
}

.g-v-info-row {
  display: table-row;
  width: 100%;
}

.g-v-info-calendar{
  color: #a1a0a0;
  padding-left: 10px;
}

.g-v-info-gamemaster-select {
  padding-left: 5px;
  text-align: center;
  background-color: #333;
  width: 200px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
  border-radius: 4px;
  color: #f0f0f0;
}

.g-v-info-players-select {
  padding-left: 5px;
  text-align: center;
  background-color: #333;
  width: 200px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
  border-radius: 4px;
  color: #f0f0f0;
}

.g-v-info-factions-select {
  padding-left: 5px;
  text-align: center;
  background-color: #333;
  width: 200px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
  border-radius: 4px;
  color: #f0f0f0;
}

.g-v-info-universes-select {
  padding-left: 5px;
  text-align: center;
  background-color: #333;
  width: 200px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
  border-radius: 4px;
  color: #f0f0f0;
}

.g-v-info-mission-select {
  margin-left: 10px;
  padding-left: 5px;
  text-align: center;
  background-color: #333;
  width: 200px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
  border-radius: 4px;
  color: #f0f0f0;
}

.g-v-info-rule-select {
  margin-left: 10px;
  padding-left: 5px;
  text-align: center;
  background-color: #333;
  width: 200px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
  border-radius: 4px;
  color: #f0f0f0;
}

.g-v-info-deploy-select {
  margin-left: 10px;
  padding-left: 5px;
  text-align: center;
  background-color: #333;
  width: 200px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
  border-radius: 4px;
  color: #f0f0f0;
}

.g-v-info-layout-select {
  margin-left: 10px;
  padding-left: 5px;
  text-align: center;
  background-color: #333;
  width: 200px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
  border-radius: 4px;
  color: #f0f0f0;
}



.g-v-info-notes-input {
  width: 200px;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  outline: none;
  background-color: #333;
  color: #f0f0f0;
}

.g-v-info-cell {
  display: table-cell;
  font-size: 12px;
  align-content: center;
}

.g-v-info-cell-header {
  width: 45%;
  display: table-cell;
  font-size: 12px;
  align-content: center;
}

.g-v-info-input-number {
  width: 100px;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 10px;
  outline: none;
  background-color: #333;
  color: #f0f0f0;
}

.g-v-info-delete-button {
  background-color: #333;
  color: #f0f0f0;
  padding: 5px 5px;
  cursor: pointer;

  border: 1px solid #b0afaf;
  font-size: 12px;
  outline: none;
  border-radius: 4px;
}

.g-v-info-pick-random-mission-button {
  background-color: #333;
  color: #f0f0f0;
  padding: 5px 5px;
  cursor: pointer;
  margin-left: 10px ;
  border: 1px solid #b0afaf;
  font-size: 12px;
  outline: none;
  border-radius: 4px;
}

.g-v-info-pick-random-rule-button {
  background-color: #333;
  color: #f0f0f0;
  padding: 5px 5px;
  cursor: pointer;
  margin-left: 10px ;
  border: 1px solid #b0afaf;
  font-size: 12px;
  outline: none;
  border-radius: 4px;
}

.g-v-info-add-player-button {
  background-color: #333;
  color: #f0f0f0;
  padding: 5px;
  cursor: pointer;
  border: 1px solid #b0afaf;
  font-size: 12px;
  outline: none;
  border-radius: 4px;

  margin-left: 10px;
}

.g-v-info-add-player-button:disabled {
  background-color: #333;
  color: #b0afaf;
  padding: 5px;
  cursor: pointer;
  border: 1px solid #282c34;
  font-size: 12px;
  outline: none;
  border-radius: 4px;

  margin-left: 10px;
}

.g-v-c-g-r {
  display: table-row;
  width: 100%;
}

.g-v-c-g-r-header {
  display: table-cell;
  padding-right: 10px;
  font-size: 12px;
  color: #a1a0a0;
  text-align: left;
  font-weight: bold;
}

.g-v-c-g-r-cell-name {
  display: table-cell;
  padding-right: 10px;
  font-size: 12px;
  color: #a1a0a0;
}

.g-v-c-g-r-cell-survived {
  display: table-cell;
  padding-right: 10px;
  text-align: center;
  color: #a1a0a0;
}

.g-v-c-g-r-cell-enemy-destroyed {
  display: table-cell;
  padding-right: 10px;
  text-align: center;
  font-size: 12px;
  color: #a1a0a0;
}

.g-v-c-g-r-cell-mark-greatness {
  display: table-cell;
  padding-right: 10px;
  text-align: center;
  color: #a1a0a0;
}

.g-v-settings-icon {
  padding-right: 5px;
}


.g-v-mission-name {
  padding-top: 15px;
  font-size: 14px;
  color: #a1a0a0;
  font-weight: bold;

}

.g-v-layout {
  width: 70%;
  height: 70%;
}

.g-v-deploy {
  width: 70%;
  height: 70%;
}

.g-v-mission-brief {
  font-size: 14px;
  color: #a1a0a0;
  font-style: italic;
}


.g-v-mission-text {
  font-size: 14px;
  color: #a1a0a0;

}

.g-v-mission-text > pre {
  white-space: pre-wrap;
}

.g-v-mission > h6 {
  display: inline;
}

.g-v-roster-lock-button {
  margin-left: 15px;
}