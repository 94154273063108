
.t-all-vtable-container {
  width: 100%;
  margin: 20px auto;
  text-align: center;
}

.t-all-vtable-title {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.t-all-vtable {
  width: 100%;
  border-collapse: collapse;
  /* background-color: #2e2e2e; */
}

.t-all-vtable-head {
  background-color: #333333;
}

.t-all-vtable-header {
  padding: 12px 15px;
  border: 1px solid #444;
  font-weight: bold;
  color: #f0f0f0;
  text-align: center;
}

/* .vtable-body .vtable-row:nth-child(even) {
    background-color: #262626;
}
*/

.t-all-vtable-row:hover {
  background-color: #333333;
}

.t-all-vtable-cell {
  padding: 12px 15px;
  border: 1px solid #444;
  color: #ddd;
}

.t-all-vtable-cell-name {
  text-align: left;
}

.t-all-vtable-cell-date {
  width: 20%;
}

.t-all-vtable-title2{
  text-align: left;
  font-size: 36px;
  color: white;
  font-weight: 700;
  margin-bottom: 30px;
}

.t-all-vtable-cell-t-name {
  text-align: left;
}

.t-all-tlist-btn {
  background-color: #333;
  color: #f0f0f0;
  font-family: Montserrat ;
  border: none;
  padding: 10px 15px;
  margin-right: 5px;
  /* border-radius: 5px; */
  cursor: pointer;
}

.t-all-tlist-btn:hover {
  background-color: #d71f26;
}

.t-all-tlist-btn-active {
  background-color: #d71f26;
  color: #f0f0f0;
  font-family: Montserrat ;
  border: none;
  padding: 10px 15px;
  margin-right: 5px;
  /* border-radius: 5px; */
  cursor: pointer;
}