

.h-pagination {
    display: flex;
    justify-content: right;
    margin-top: 40px;
    gap: 5px;
    color: #aaa;

}

.h-pagination a {
    color: #aaa;
    text-decoration: none;
}

.h-pagination a.active {
    color: #b03030;
}

.h-pagination a:hover {
    color: #b03030;
}

.h-pagination-active {
    color: #b03030 !important;
}





.h-container-cards {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    padding: 0px;
    padding-bottom: 40px;
}

.h-fa-calendar{
    color: #b03030;
}


.h-navbar-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.h-navbar-cards ul {
    list-style: none;
    display: flex;
    gap: 10px;
    padding: 5px;
    margin: 0;
    background-color: #444;
    border-radius: 10px;

}

.h-avbar-cards ul li {
    background-color: #444;
    padding: 10px 15px;
    /* border-radius: 5px; */
    color: #fff;
    cursor: pointer;
    border-radius: 10px;
}

.h-navbar-cards ul li.active {
    background-color: #d32f2f;
}

.h-navbar-cards .date {
    color: #999;
    display: flex;
    align-items: center;
}

.h-grid-2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
}


.h-card-2 {
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.h-card-2 img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.h-card-content {
    background-color: #313234;
    padding: 20px ;
    color: #ddd;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.h-card-content a {
    background-color: #313234;
}

.h-card-title {
    background-color: #313234;
    font-size: 20px;
    color: #d32f2f;
    font-weight: 600;
    margin: 0 0 10px;
}

.h-card-description {
    background-color: #313234;
    font-size: 14px;
    color: #aaa;
    margin: 0 0 15px;
    text-align: center;
}
