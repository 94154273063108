
.c-c-manage-vtable-container {
    width: 100%;
    margin: 20px auto;
    text-align: center;
}

.c-c-manage-vtable-title {
    font-size: 1.5em;
    margin-bottom: 15px;
}

.c-c-manage-vtable {
    width: 100%;
    border-collapse: collapse;
    /* background-color: #2e2e2e; */
}

.c-c-manage-vtable-head {
    background-color: #333333;
}

.c-c-manage-vtable-header {
    padding: 12px 15px;
    border: 1px solid #444;
    font-weight: bold;
    color: #f0f0f0;
    text-align: center;
}

.c-c-manage-vtable-row:hover {
    background-color: #333333;
}

.c-c-manage-vtable-cell {
    padding: 12px 15px;
    border: 1px solid #444;
    text-align: center;
    color: #ddd;
}

.c-c-manage-vtable-cell-name {
    text-align: left;
}

.c-c-manage-vtable-cell-date {
    width: 20%;
}

.c-c-manage-vtable-title2{
    text-align: left;
    font-size: 36px;
    color: white;
    font-weight: 700;
    margin-bottom: 30px;
}

.c-c-manage-tlist-btn {
    background-color: #333;
    color: #f0f0f0;
    border: none;
    padding: 10px 15px;
    margin-right: 5px;
    /* border-radius: 5px; */
    cursor: pointer;
}

.c-c-manage-tlist-btn:hover {
    background-color: #d71f26;
}

.c-c-manage-tlist-btn-active {
    background-color: #d71f26;
    color: #f0f0f0;
    border: none;
    padding: 10px 15px;
    margin-right: 5px;
    /* border-radius: 5px; */
    cursor: pointer;
}

.c-c-manage-create-game-container {
    width: 100%;
    color: #ffffff;
    padding: 20px;
}

.c-c-manage-create-game-container h2 {
    font-size: 38px;
    text-align: center;
    margin-bottom: 10px;
}


.c-c-manage-create-game-container .select {
    font-size: 38px;
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
}


.≈create-game-form label {
    margin-bottom: 5px;
    display: block;
}

.c-c-manage-create-game-form input {
    background-color: #333;
    padding: 7px;
    border: none;
    width: 100%;
    color: white;
}

.c-c-manage-create-game-form input:active {
    background-color: #333;
    padding: 7px;
    border: none;
    width: 100%;
    color: white;
}

.c-c-manage-create-game-form select {
    background-color: #333;
    padding: 7px;
    border: none;
    width: 100%;
    color: white;
}

.c-c-manage-create-game-form textarea {
    background-color: #333;
    padding: 7px;
    border: none;
    width: 100%;
    color: white;
}

.c-c-manage-create-game-form textarea:focus {
    background-color: #333;
    padding: 7px;
    border: none;
    width: 100%;
    color: white;
}

.c-c-manage-text-center {
    text-align: center;
}

.c-c-manage-text-right {
    text-align: right;
}
.c-c-manage-text-left {
    text-align: left;
}
.c-c-manage-mb-3 {
    margin-bottom: 1rem;
}
