

.t-v-mainheading-section{

  color: #f0f0f0;

  max-width: 1200px;
  margin: 0 auto;
}

.t-v-container-ch {

  padding: 20px;
}

.t-v-heading-m-ch{
  font-size: 45px;
  margin-bottom: 10px;
  text-align: center;
}

.year-ch {
  color: #d71f26;
}

.t-v-status-ch {
  display: flex;
  justify-content: space-around;
  margin: 40px 0;
  font-size: 0.9em;
}

.t-v-status-ch div {
  display: flex;
  align-items: center;
}

.t-v-status-check {
  color: #d71f26;
  margin-right: 5px;
}

.t-v-status-square {
  width: 15px;
  height: 15px;
  border: 2px solid #f0f0f0;
  display: inline-block;
  margin-right: 5px;
}

.t-v-details-ch {
  margin-top: 40px;
  font-size: 1em;
  display: flex;
  line-height: 1.6;
  gap: 20px;
}
.t-v-line1{
  border-right: solid 1px rgba(255, 255, 255, 0.349);
  padding-right: 40px;
}
.t-v-details-ch2 {
  margin-top: 20px;
  color: #929191;
}

.t-v-date-info-ch {
  margin-bottom: 10px;
  color: #929191;
}

.t-v-str {
  font-weight: 500;
  font-size: 19px;
  color: white;
}

/*  table  */

.t-v-table1-main{
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.t-v-table-container {
  width: 100%;
  margin: 20px auto;
  text-align: center;
}

.table-title {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table-head {
  background-color: #333333;
}

.t-v-table-header {
  padding: 12px 15px;
  border: 1px solid #444;
  font-weight: bold;
  color: #f0f0f0;
  text-align: center;
}

.table-row:hover {
  background-color: #333333;
}

.table-cell {
  padding: 12px 15px;
  border: 1px solid #444;
  text-align: center;
  color: #ddd;
}

.t-v-table-cell-p-name {
  text-align: left;
}

.t-v-table-title2{
  text-align: left;
  font-size: 36px;
  color: white;
  font-weight: 700;
  margin-bottom: 30px;
}


/* tag */

.div-voting-tags {
  margin-top: 5px;
}

.span-voting-tag {
  display: inline-block; /* Inline elements with width and height. TL;DR they make the icon buttons stack from left-to-right instead of top-to-bottom */
  position: relative; /* All 'absolute'ly positioned elements are relative to this one */
  padding-right: 10px;
}

.tag {
  /* background-color: #d71f26; */
  color: #fff;
  font-size: 0.7em;
}

.tag_badge {
  background-color: #fa3e3e;
  border-radius: 2px;
  color: white;

  padding: 1px 3px;
  font-size: 10px;

  position: absolute; /* Position the badge within the relatively positioned button */
  top: 0;
  right: 0;
}

/* table 2 */

.t-v-table2-ch-main{
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.t-v-container3 {
  width: 100%;


  text-align: center;
}

.title3 {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.t-v-rounds3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.t-v-round-btn3 {
  background-color: #333;
  color: #f0f0f0;
  font-family: Montserrat ;
  border: none;
  padding: 10px 15px;
  margin-right: 5px;
  /* border-radius: 5px; */
  cursor: pointer;
}

.t-v-round-btn3.t-v-active3 {
  background-color: #d71f26;
}

.search3 {
  display: flex;
  align-items: center;
}

.search-input3 {
  padding: 12px 32px 12px 16px;
  background-color: #333;
  border: 1px solid rgba(255, 255, 255, 0.356);
  border: none;
  color: white;
  outline: none;
}

.search-btn3 {
  padding: 12px 16px 12px 16px;
  border: none;
  background-color: #d71f26;
  color: #fff;
  cursor: pointer;
}

.t-v-table3 {
  width: 100%;
  border-collapse: collapse;
  /* background-color: #2e2e2e; */
  margin-top: 10px;
}

.t-v-thead3 {
  background-color: #333333;
}

.t-v-table-header3 {
  padding: 12px 15px;
  border: 1px solid #444;
  font-weight:700;
  color: #f0f0f0;
  text-align: center;
  font-size: 24px;
}

/* .tbody3 .table-row3:nth-child(even) {
    background-color: #262626;
} */

.t-v-table-row3:hover {
  background-color: #333333;
}

.t-v-table-cell3 {
  padding: 12px 15px;
  border: 1px solid #444;
  color: #ddd;
  text-align: center;
}

.t-v-table-cell-g-name {
  text-align: left;
}

.t-v-vtable-container {
  width: 100%;
  margin: 20px auto;
  text-align: center;
}

.vtable-title {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.t-v-vtable {
  width: 100%;
  border-collapse: collapse;
  /* background-color: #2e2e2e; */
}

.t-v-vtable-head {
  background-color: #333333;
}

.t-v-vtable-header {
  padding: 12px 15px;
  border: 1px solid #444;
  font-weight: bold;
  color: #f0f0f0;
  text-align: center;
}

.t-v-vtable-row:hover {
  background-color: #333333;
}

.t-v-vtable-cell {
  padding: 12px 15px;
  border: 1px solid #444;
  text-align: center;
  color: #ddd;
}
.t-v-vtable-title2{
  text-align: left;
  font-size: 36px;
  color: white;
  font-weight: 700;
  margin-bottom: 30px;
}

.t-v-buttons-container {
  padding-top: 50px;
}

.t-v-tlist-btn {
  background-color: #333;
  color: #f0f0f0;
  font-family: Montserrat ;
  border: none;
  padding: 10px 15px;
  margin-right: 5px;
  /* border-radius: 5px; */
  cursor: pointer;
}

.t-v-tlist-btn:hover {
  background-color: #d71f26;
}

.t-v-tlist-btn-active {
  background-color: #d71f26;
  color: #f0f0f0;
  font-family: Montserrat ;
  border: none;
  padding: 10px 15px;
  margin-right: 5px;
  /* border-radius: 5px; */
  cursor: pointer;
}

.t-v-alerts-open {
  color: #41cb1f;
  padding: 20px;
}

.t-v-alerts-closed {
  color: #d71f26;
  padding: 20px;
}

.t-v-join-info-ch {
  margin-left: -15px;
  color: #929191;
}

.t-v-line2{
  border-left: solid 1px rgba(255, 255, 255, 0.349);
  padding-right: 40px;
}

.t-v-text-center {
  text-align: center;
}


