
.g-all-vtable-container {
  width: 100%;
  margin: 20px auto;
  text-align: center;
}

.g-all-vtable-title {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.g-all-vtable {
  width: 100%;
  border-collapse: collapse;
  /* background-color: #2e2e2e; */
}

.g-all-vtable-head {
  background-color: #333333;
}

.g-all-vtable-header {
  padding: 12px 15px;
  border: 1px solid #444;
  font-weight: bold;
  color: #f0f0f0;
  text-align: center;
}

.g-all-vtable-row:hover {
  background-color: #333333;
}

.g-all-vtable-cell {
  padding: 12px 15px;
  border: 1px solid #444;
  text-align: center;
  color: #ddd;
}

.g-all-vtable-cell-name {
  text-align: left;
}

.g-all-vtable-cell-details {
  width: 100%;
  display: inline-flex;
}



.g-all-vtable-cell-settings {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
}

.g-all-vtable-cell-limit {
  font-size: 12px;
  color: #888;
  text-align:  right;
}

.g-all-vtable-cell-crusade {
  font-size: 12px;
  color: #888;
  text-align:  right;
  padding-right: 10px;
}

.g-all-vtable-cell-player {
  display: inline-block;
  font-size: 12px;
  color: #888;

}

.g-all-vtable-cell-players {
  display: inline-block;
  width: 80%;
}

.g-all-vtable-cell-player-winner {
  display: inline-block;
  font-size: 12px;
  color: #20ab42;
}

.g-all-vtable-cell-player-vs {
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 10px;
  color: #888;
}

.g-all-vtable-cell-date {
  width: 20%;
}

.g-all-vtable-title2{
  text-align: left;
  font-size: 36px;
  color: white;
  font-weight: 700;
  margin-bottom: 30px;
}

.g-all-tlist-btn {
  background-color: #333;
  color: #f0f0f0;
  font-family: Montserrat ;
  border: none;
  padding: 10px 15px;
  margin-right: 5px;
  /* border-radius: 5px; */
  cursor: pointer;
}

.g-all-tlist-btn:hover {
  background-color: #d71f26;
}

.g-all-tlist-btn-active {
  background-color: #d71f26;
  color: #f0f0f0;
  font-family: Montserrat ;
  border: none;
  padding: 10px 15px;
  margin-right: 5px;
  /* border-radius: 5px; */
  cursor: pointer;
}
