

.footer {
  padding: 50px 0 20px 0px;
  color: #ffffff;
}

.footer-content {
  display: flex;
  justify-content:end;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-links {
  display: flex;
  justify-content:end;
  width: 30%;
}

.footer-section {
  flex: 1;
  padding: 0 15px;
}

.footer-section h4 {
  color:#b03030;
  margin-bottom: 10px;
}

.footer-section a {
  display: block;
  color: #b0b0b0;
  text-decoration: none;
  margin-bottom: 5px;
  transition: color 0.3s;
}

.footer-section a:hover {
  color: #e60023;
}

.footer-bottom {
  margin-top: 20px;
  text-align: center;
  padding: 10px;
  border-top: 1px solid #333333;
  color: #b0b0b0;
  font-size: 0.9rem;
}

