


.c-c-hq {

}

.c-c-hq-container {
    width: 100%;
    color: #ffffff;
    padding: 20px;
}

.c-c-hq-name {
    text-align: center;
}

.c-c-hq-row {
    padding-bottom: 50px;
}

.c-c-hq-text {
    font-size: 14px;
    color: #a1a0a0;
}

.c-c-hq-mission-row {
    padding-bottom: 10px;
}

.c-c-hq-agenda-row {
    padding-bottom: 10px;
}

.c-c-hq-agenda-name {
    font-weight: bold;
}

.c-c-hq-mission-name {
    font-weight: bold;
}

.c-c-hq-alliance {
    display: table-row;
    width: 100%;
}

.c-c-hq-alliance-players {
    display: table-cell;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 14px;
    color: #a1a0a0;
    text-align: center;
}

.c-c-hq-alliance-players-header {
    display: table-cell;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 12px;
    color: #a1a0a0;
    text-align: center;
    font-weight: bold;
}


.c-c-hq-alliance-name-header {
    display: table-cell;
    padding-right: 10px;
    font-size: 12px;
    color: #a1a0a0;
    text-align: left;
    font-weight: bold;
}

.c-c-hq-alliance-name {
    font-size: 14px;
    color: #a1a0a0;
    text-align: left;
    display: inline;
}

.c-c-hq-alliance-title-name {
    display: inline-block;
}

.c-c-hq-alliance-title-icon {
    display: inline-block;
    padding-left: 30px;
}


.c-c-hq-alliance-name-input {
    font-size: 12px;
    color: #a1a0a0;
    text-align: left;
    display: inline;
    background-color: #333;
    border: 1px solid #ccc;
    outline: none;
    border-radius: 4px;
}

.c-c-hq-alliance-discord-input {
    font-size: 12px;
    color: #a1a0a0;
    text-align: left;
    display: inline;
    background-color: #333;
    border: 1px solid #ccc;
    outline: none;
    border-radius: 4px;
}

.c-c-hq-alliance-discord-icon {
    display: inline-block;
    padding-left: 10px;
}


.c-c-hq-alliance-score-header {
    display: table-cell;
    font-size: 12px;
    color: #a1a0a0;
    text-align: center;
    font-weight: bold;
}

.c-c-hq-alliance-score {
    display: table-cell;
    font-size: 14px;
    color: #a1a0a0;
    text-align: center;
}

/**/

.c-c-hq-state-title {
}

.c-c-hq-state-title-name {
    display: inline-block;
}

.c-c-hq-state-title-icon {
    display: inline-block;
    padding-left: 30px;
}

.c-c-hq-state {
    display: table-row;
    width: 100%;
}


.c-c-hq-state-cell {
    display: table-cell;
    padding-right: 10px;
}

.c-c-hq-state-name-header {
    display: table-cell;
    padding-right: 10px;
    font-size: 12px;
    color: #a1a0a0;
    text-align: left;
    font-weight: bold;
}

.c-c-hq-state-name {
    font-size: 14px;
    color: #a1a0a0;
    text-align: left;
    display: inline;
}

.c-c-hq-state-name-input {
    font-size: 12px;
    color: #a1a0a0;
    text-align: left;
    display: inline;
    background-color: #333;
    border: 1px solid #ccc;
    outline: none;
    border-radius: 4px;
}


.c-c-hq-state-date-header {
    display: table-cell;
    padding-right: 10px;
    font-size: 12px;
    color: #a1a0a0;
    text-align: left;
    font-weight: bold;
}

.c-c-hq-state-date {
    display: table-cell;
    padding-right: 10px;
    font-size: 14px;
    color: #a1a0a0;
    text-align: left;
}

.c-c-hq-state-notify {
    display: table-cell;
    padding-right: 10px;
    font-size: 14px;
    color: #a1a0a0;
    text-align: center;
}

.c-c-hq-state-notify-test {
    background: none !important;
    border: none;
    padding: 0 !important;
    text-decoration: underline;
    cursor: pointer;
    color: #f0f0f0;
}


/**/

.c-c-hq-players-title-name {
    display: inline-block;
}

.c-c-hq-players-title-icon {
    display: inline-block;
    padding-left: 30px;
}

.c-c-hq-players {
    display: table-row;
    width: 100%;
}

.c-c-hq-players-name-header {
    display: table-cell;
    padding-right: 10px;
    font-size: 12px;
    color: #a1a0a0;
    text-align: left;
    font-weight: bold;
}

.c-c-hq-players-alliance-header {
    display: table-cell;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 12px;
    color: #a1a0a0;
    text-align: left;
    font-weight: bold;
}

.c-c-hq-players-role-header {
    display: table-cell;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 12px;
    color: #a1a0a0;
    text-align: left;
    font-weight: bold;
}

.c-c-hq-players-faction-header {
    display: table-cell;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 12px;
    color: #a1a0a0;
    text-align: left;
    font-weight: bold;
}

.c-c-hq-players-timezone-header {
    display: table-cell;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 12px;
    color: #a1a0a0;
    text-align: left;
    font-weight: bold;
}

.c-c-hq-players-score-header {
    display: table-cell;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 12px;
    color: #a1a0a0;
    text-align: center;
    font-weight: bold;
}

.c-c-hq-players-name {
    display: table-cell;
    padding-right: 10px;
    font-size: 14px;
    color: #a1a0a0;
    text-align: left;
}

.c-c-hq-players-alliance {
    display: table-cell;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 14px;
    color: #a1a0a0;
    text-align: left;
}

.c-c-hq-players-role {
    display: table-cell;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 14px;
    color: #a1a0a0;
    text-align: left;
}

.c-c-hq-players-faction {
    display: table-cell;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 14px;
    color: #a1a0a0;
    text-align: left;
}

.c-c-hq-players-timezone {
    display: table-cell;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 14px;
    color: #a1a0a0;
    text-align: left;
}

.c-c-hq-players-score {
    display: table-cell;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 14px;
    color: #a1a0a0;
    text-align: center;
}

/**/

.c-c-hq-relics-row {
    display: table-row;
    width: 100%;
}
.c-c-hq-relics-cell {
    display: table-cell;
    padding-right: 5px;
    font-size: 12px;
}

/**/

.c-c-hq-ranks-row {
    display: table-row;
    width: 100%;
}
.c-c-hq-ranks-cell {
    display: table-cell;
    padding-right: 5px;
    font-size: 12px;
}

/**/

.c-c-hq-bt-row {
    display: table-row;
    width: 100%;
}
.c-c-hq-bt-cell {
    display: table-cell;
    padding-right: 5px;
    font-size: 12px;
}

/**/

.c-c-hq-bs-row {
    display: table-row;
    width: 100%;
}
.c-c-hq-bs-cell {
    display: table-cell;
    padding-right: 5px;
    font-size: 12px;
}

/**/


.c-c-hq-games {
    display: table-row;
    width: 100%;
}

.c-c-hq-games-name-header {
    display: table-cell;
    padding-right: 10px;
    font-size: 12px;
    color: #a1a0a0;
    text-align: left;
    font-weight: bold;
}

.c-c-hq-games-winner-header {
    display: table-cell;
    padding-right: 10px;
    font-size: 12px;
    color: #a1a0a0;
    text-align: left;
    font-weight: bold;
}

.c-c-hq-games-date-header {
    display: table-cell;
    padding-right: 10px;
    font-size: 12px;
    color: #a1a0a0;
    text-align: left;
    font-weight: bold;
}

.c-c-hq-games-name {
    display: table-cell;
    padding-right: 10px;
    font-size: 14px;
    color: #a1a0a0;
    text-align: left;
}

.c-c-hq-games-winner {
    display: table-cell;
    padding-right: 10px;
    font-size: 14px;
    color: #a1a0a0;
    text-align: left;
}

.c-c-hq-games-date {
    display: table-cell;
    padding-right: 10px;
    font-size: 14px;
    color: #a1a0a0;
    text-align: left;
}

/**/


.c-c-hq-map-image img {
    padding-right: 10px;
}

.c-c-hq-arrow {
    color: white;
    display: inline;
}